import { MainLayout } from "../layouts/MainLayout"
import { useLocale } from "../hooks/useLocale"
import { Header } from "@op-str/op-component-library"

export const RouteRenderErrorFallback = () => {
  const loc = useLocale()

  return (
    <MainLayout title="Error">
      <Header
        elevation
        title={{
          bold: loc.oneportal.router.messages.renderError.get(),
        }}
      />
    </MainLayout>
  )
}
