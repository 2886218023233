import { AccountInfo } from "@azure/msal-browser"
import { useContainer } from "./useContainer"
import { useConfig } from "./useConfig"

export const useMsalAccount = (): AccountInfo | undefined => {
  const activeAccount = useContainer()
    .msal.getAllAccounts()
    .filter((account) => account.environment == useConfig().msal.environment)[0]

  return activeAccount ?? undefined
}
