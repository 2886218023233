import { OrderTabs } from "./helpers/types/OrderTabs"
import { OrderId } from "./services/api/types/tags/OrderId"

export type SalesLinks = ReturnType<typeof getSalesAppLinks>

// hide links inside a factory to prevent global imports
export const getSalesAppLinks = () => {
  return {
    root: () => "/",
    orders: () => "/orders",
    orderDetails: (orderId: OrderId = ":orderId" as OrderId, tab?: OrderTabs) => {
      const params = tab ? `?tab=${tab}` : ""
      return `/orders/${orderId}${params}`
    },
    salesHelp: () => "/sales-help",
    salesReleases: () => "/sales-releases",
  }
}
