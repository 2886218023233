import { ClickAwayListener } from "@mui/material"
import clsx from "clsx"
import React, { ReactNode, useEffect, useMemo, useState } from "react"
import { Step } from "react-joyride"
import styled, { keyframes } from "styled-components"
import { OneportalSidebar } from "../../sales/components/OneportalSidebar"
import { theme } from "@op-str/op-component-library"
import { HelpButton } from "../../sales/components/HelpButton/HelpButton"
import { Navbar } from "../components/Navbar/Navbar"
import { BackLinkType } from "../components/Navbar/types/BackLinkType"
import { Sidebar } from "../components/Sidebar/Sidebar"
import { useShowProgressNotification } from "../hooks/useShowProgressNotification"
import { useWindowWidth } from "../hooks/useWindowSize"
import { ContentMediumWidth } from "./partials/ContentMediumWidth"
import { ContentSpaceBetween } from "./partials/ContentSpaceBetween"
import { PageTitle } from "./PageTitle"
import { Panel } from "./Panel"
import { DisruptionBanner } from "../components/DisruptionBanner"
import { Footer } from "../components/Footer"

export type IntroductionParams = {
  steps: Step[]
  disableScrolling?: boolean
  onStart?: () => void
}

export type MainLayoutProps = {
  children: ReactNode
  sidebar?: ReactNode
  panel?: ReactNode
  title: string
  backLink?: string
  backLinkType?: BackLinkType
  narrow?: boolean
  medium?: boolean
  className?: string
  showNavbar?: boolean
  customNavbar?: ReactNode
  navbarRightContent?: ReactNode
  customProfile?: ReactNode
  showGuideButton?: boolean
  showFooter?: boolean
}

let previousLayoutType: string | undefined = undefined
let previousNavbarType: string | undefined = undefined

const persistedSidebarCollapsed = {
  get: (defaultValue: boolean): boolean =>
    JSON.parse(localStorage.getItem("sidebar-collapsed") ?? JSON.stringify(defaultValue)),
  set: (value: boolean) => localStorage.setItem("sidebar-collapsed", JSON.stringify(value)),
}
const persistedPanelCollapsed = {
  get: (defaultValue: boolean): boolean =>
    JSON.parse(localStorage.getItem("panel-collapsed") ?? JSON.stringify(defaultValue)),
  set: (value: boolean) => localStorage.setItem("panel-collapsed", JSON.stringify(value)),
}

export const MainLayout = (props: MainLayoutProps) => {
  const {
    children,
    title,
    sidebar,
    panel = null,
    backLink,
    backLinkType,
    narrow,
    medium,
    className,
    showNavbar = true,
    customNavbar,
    navbarRightContent,
    showGuideButton = true,
    customProfile,
    showFooter = false,
  } = props
  const progressNotification = useShowProgressNotification()
  const windowWidth = useWindowWidth()
  const isSmallScreen = windowWidth < theme.mui.breakpoints.values.md
  const [sidebarCollapsed, setSidebarCollapsed] = useState(persistedSidebarCollapsed.get(false) || isSmallScreen)
  const [panelCollapsed, setPanelCollapsed] = useState(persistedPanelCollapsed.get(false) || isSmallScreen)
  const showPanel = panel !== null
  const showSidebar = sidebar !== null && !showPanel
  const centerContent = !showSidebar && !showPanel

  const layoutType = panel ? "panel" : "sidebar"
  const animateLayout = useMemo(() => previousLayoutType !== undefined && previousLayoutType !== layoutType, [])

  const navbarType = backLink ? "action" : "logo"
  const animateNavbar = useMemo(() => previousNavbarType !== undefined && previousNavbarType !== navbarType, [])

  previousLayoutType = layoutType
  previousNavbarType = navbarType

  const handleToggleSidebar = () => {
    if (!isSmallScreen) {
      persistedSidebarCollapsed.set(!sidebarCollapsed)
    }
    setSidebarCollapsed(!sidebarCollapsed)
  }

  const handleTogglePanel = () => {
    if (!isSmallScreen) {
      persistedPanelCollapsed.set(!panelCollapsed)
    }
    setPanelCollapsed(!panelCollapsed)
  }

  const handleScreenSizeChanges = () => {
    if (isSmallScreen) {
      setSidebarCollapsed(true)
      setPanelCollapsed(true)
    } else {
      setSidebarCollapsed(persistedSidebarCollapsed.get(false) || false)
      setPanelCollapsed(persistedPanelCollapsed.get(false) || false)
    }
  }
  useEffect(handleScreenSizeChanges, [isSmallScreen])

  const handleClickOutsideSidebar = () => {
    if (isSmallScreen) {
      setSidebarCollapsed(true)
    }
  }

  const handleClickOutsidePanel = () => {
    if (isSmallScreen) {
      setPanelCollapsed(true)
    }
  }

  useEffect(() => {
    if (!progressNotification.isClear) progressNotification.clear()
  }, [])

  return (
    <Root className={className}>
      <DisruptionBanner />
      {showGuideButton && <HelpButton legal={false} />}
      <PageTitle title={title} />
      {showNavbar && (
        <>
          {customNavbar ? (
            customNavbar
          ) : (
            <Navbar
              backLink={backLink}
              customProfile={customProfile}
              backLinkType={backLinkType}
              layout={navbarType}
              animate={animateNavbar}
              customRightContent={navbarRightContent}
              collapsed={sidebarCollapsed}
            />
          )}
        </>
      )}

      <Layout
        className={clsx(
          layoutType,
          animateLayout && "animate",
          panelCollapsed && "panel-collapsed",
          sidebarCollapsed && "sidebar-collapsed"
        )}
      >
        <Wrapper>
          {showSidebar && (
            <ClickAwayListener onClickAway={handleClickOutsideSidebar}>
              <Sidebar collapsed={sidebarCollapsed} onToggle={handleToggleSidebar}>
                {sidebar ?? <OneportalSidebar collapsed={sidebarCollapsed} />}
              </Sidebar>
            </ClickAwayListener>
          )}

          <ContentSpaceBetween id="main-layout-scroller" centerContent={centerContent}>
            {narrow ? (
              <ContentNarrowWidth>
                {children} <FeedbackMarker />
              </ContentNarrowWidth>
            ) : medium ? (
              <ContentMediumWidth>
                {children} <FeedbackMarker />
              </ContentMediumWidth>
            ) : (
              <ContentMaxWidth>
                {children} <FeedbackMarker />
              </ContentMaxWidth>
            )}
            {showFooter && <Footer />}
          </ContentSpaceBetween>

          {showPanel && (
            <ClickAwayListener onClickAway={handleClickOutsidePanel}>
              <Panel collapsed={panelCollapsed} onToggle={handleTogglePanel}>
                {panel}
              </Panel>
            </ClickAwayListener>
          )}
        </Wrapper>
      </Layout>
    </Root>
  )
}

const FeedbackMarker = styled.div`
  width: 100px;
  margin: auto;
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100%;
`

const animateSidebar = keyframes`
  from {
    transform: translateX(-${theme.ui.sidebar.width});
  }

  to {
    transform: translateX(0px);
  }
`

const animateSidebarCollapsed = keyframes`
  from {
    transform: translateX(-${theme.ui.sidebar.widthCollapsed});
  }

  to {
    transform: translateX(0px);
  }
`

const animatePanel = keyframes`
  from {
    transform: translateX(${theme.ui.sidebar.width});
  }

  to {
    transform: translateX(0px);
  }
`

const animatePanelCollapsed = keyframes`
  from {
    transform: translateX(${theme.ui.sidebar.widthCollapsed});
  }

  to {
    transform: translateX(0px);
  }
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  height: 300px;

  &.sidebar.animate {
    ${theme.mui.breakpoints.up("md")} {
      animation: ${animateSidebar} 0.2s linear;
    }

    ${theme.mui.breakpoints.down("md")} {
      animation: ${animateSidebarCollapsed} 0.2s linear;
    }

    &.sidebar-collapsed {
      animation: ${animateSidebarCollapsed} 0.2s linear;
    }
  }

  &.panel.animate {
    ${theme.mui.breakpoints.up("md")} {
      animation: ${animatePanel} 0.2s linear;
    }

    ${theme.mui.breakpoints.down("md")} {
      animation: ${animatePanelCollapsed} 0.2s linear;
    }

    &.sidebar-collapsed {
      animation: ${animatePanelCollapsed} 0.2s linear;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  position: relative;
  height: 100%;
`

const ContentMaxWidth = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: fit-content;
`

const ContentNarrowWidth = styled.div`
  position: relative;
  flex-grow: 1;
  max-width: ${theme.ui.content.widthNarrow};
  height: fit-content;
`
