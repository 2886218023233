import { TextLink, TextVariant, theme } from "@op-str/op-component-library"
import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import styled from "styled-components"

export type LinkProps = {
  label: string
  link: string
  newTab?: boolean
  visited?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  variant?: TextVariant
}

export const Link = (props: LinkProps) => {
  const { label, link, onClick, newTab = false, visited = false, variant } = props
  const renderLinkLabel = () => (
    <TextLink onClick={onClick} variant={variant}>
      {label}
    </TextLink>
  )
  if (newTab)
    return (
      <RootExternalLink href={link} target="_blank" $visited={visited}>
        {renderLinkLabel()}
      </RootExternalLink>
    )
  return (
    <RootLink to={link} target={newTab ? "_blank" : ""} $visited={visited}>
      {renderLinkLabel()}
    </RootLink>
  )
}

const getStyled = (visited: boolean) => `
  text-decoration: none;
  ${
    visited &&
    `&:visited > span {
        color: ${theme.color.blue13};`
  }
`

const RootLink = styled(ReactRouterLink)<{ $visited: boolean }>`
  ${(p) => getStyled(p.$visited)}
`

const RootExternalLink = styled.a<{ $visited: boolean }>`
  ${(p) => getStyled(p.$visited)}
`
