import { i18n, Resource, TOptions } from "i18next"

import en from "../locales/en.json"
import { createAccessor } from "@stg-oneportal/corets-accessor"

export const getAppLocales = (): Resource => ({
  en: { translation: en },
})

export const createAccessibleLocale = (translator: i18n) => {
  return createAccessor(en, (source, key, options?: TOptions) => {
    return translator.t(key?.toString() ?? "-", options)
  })
}
