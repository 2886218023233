import { Menu } from "@mui/material"
import styled from "styled-components"
import { theme } from "@op-str/op-component-library"

export const HelpMenu = styled(Menu)`
  .MuiMenu-paper {
    margin-top: -${theme.spacing.m};
    padding: ${theme.spacing.m};
    background-color: ${theme.color.black3};
    color: ${theme.color.white};
    border-radius: ${theme.borderRadius};
    min-width: 162px;
    padding: ${theme.spacing.m} 0;
  }

  .MuiMenu-list {
    padding: 0;
  }

  .MuiMenuItem-root {
    padding: 0;
    font-size: ${theme.fontSize.m};
    line-height: ${theme.lineHeight.m};

    a {
      text-decoration: none;
      color: ${theme.color.white};
      width: 100%;
    }

    svg {
      width: 12px;
      height: 12px;
      margin-left: auto;
    }

    &.MuiListItem-root {
      & > * {
        padding: 2px ${theme.spacing.m};
      }
    }
  }

  .MuiListItem-root:hover {
    background-color: ${theme.color.grey1};
  }

  .Mui-disabled {
    opacity: 1;
  }
`
