import { PublicClientApplication } from "@azure/msal-browser"
import { createBrowserHistory } from "history"
import i18next from "i18next"
import languageDetector from "i18next-browser-languagedetector"
import React, { ReactNode } from "react"
import { initReactI18next } from "react-i18next"
import { getAppConfig } from "./config"
import { User } from "./sales/services/api/types/User"
import { createAccessibleLocale, getAppLocales } from "./locales"
import { ProgressNotification } from "./oneportal/components/Navbar/types/ProgressNotification"
import { fetchMsalAccessToken } from "./oneportal/helpers/fetchMsalAccessToken"
import { translatorLogger } from "./oneportal/helpers/translatorLogger"
import { AccessTokenFetcher } from "./oneportal/helpers/types/AccessTokenFetcher"
import { Logger } from "@stg-oneportal/logger"
import { createValue } from "@stg-oneportal/corets-value"
import { createApiClient } from "./oneportal/services/api/createApiClient"
import { createLogoutService } from "./oneportal/services/logout/createLogoutService"
import { createApplicationInsights } from "./oneportal/services/insights/createApplicationInsights"
import { createCustomerPortalApiClient } from "./oneportal/services/api/customer/createCustomerPortalApiClient"
import { createSalesApiClient } from "./sales/services/api/createSalesApiClient"
import { getOnePortalAppLinks } from "./oneportal/links"
import { getAppRoutes as getOnePortalRoutes } from "./oneportal/routes"
import { getAppRoutes as getSalesRoutes } from "./sales/routes"
import { getSalesAppLinks } from "./sales/links"
import { createButterCmsService } from "./oneportal/services/butterCms/createButterCmsService"

const config = getAppConfig()
const locales = getAppLocales()

const onePortalLinks = getOnePortalAppLinks()
const routesOnePortal = getOnePortalRoutes(config, onePortalLinks)

const salesLinks = getSalesAppLinks()
const routesSales = getSalesRoutes(config, salesLinks)

// controls the global progress overlay
const showProgressOverlay = createValue(true)
// controls progress bar that you see between page transitions
const showProgressIndicator = createValue(false)
// indicates that some api calls are being executed
const showApiCallsIndicator = createValue(false)
// controls progress bar that you see when update profile
const showProgressNotification = createValue<ProgressNotification>(ProgressNotification.None)
// indicates whether current page load is the first one, useful for animations
const isFirstPageLoad = createValue(true)
// controls title of navbar
const navbarTitle = createValue<ReactNode | undefined>(undefined)
// controls link of navbar
const navbarLink = createValue<string | undefined>(undefined)
// profile info
const profileInfo = createValue<User | undefined>(undefined)

// msal configuration
const msal = new PublicClientApplication({
  auth: {
    clientId: config.msal.clientId,
    authority: config.msal.authority,
    redirectUri: config.msal.redirectUri,
    knownAuthorities: [config.msal.knownAuthorities],
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
})

const msalScopes = config.msal.scopes
const environment = config.msal.environment

const logoutService = createLogoutService(msal, config.msal.postLogoutRedirectUri)
const browserHistory = createBrowserHistory()
const applicationInsights = createApplicationInsights({
  key: config.applicationInsights.key!,
  correlationHeaderDomains: config.applicationInsights.correlationHeaderDomains!,
  browserHistory,
})

const fetchAccessToken: AccessTokenFetcher = (logger) => fetchMsalAccessToken({ msal, msalScopes, environment, logger })

// customer portal api client
const customerPortalApiClient = createCustomerPortalApiClient(
  createApiClient({
    endpoint: config.api.customerPortalEndpoint,
    fetchAccessToken,
    logger: new Logger("Customer"),
    indicator: showApiCallsIndicator,
  })
)

const salesApiClient = createSalesApiClient(
  createApiClient({
    endpoint: config.api.salesPortalEndpoint,
    fetchAccessToken,
    logger: new Logger("Sales"),
    indicator: showApiCallsIndicator,
    handleForbiddenError: () => browserHistory.push(onePortalLinks.notAuthorized()),
  })
)

const butterCmsService = createButterCmsService({
  token: config.butterCms.token,
  defaultLocale: config.butterCms.defaultLocale,
  fetchPreview: config.butterCms.fetchPreview,
})

const translator = i18next
  .createInstance({
    debug: true,
    fallbackLng: config.translator.fallbackLanguage,
    supportedLngs: Object.keys(locales),
    resources: locales,
    interpolation: { escapeValue: false },
  })
  .use(translatorLogger)
  .use(languageDetector)
  .use(initReactI18next)

// initialize translator immediately
translator.init()

// create a dynamically accessible dictionary for translations
const accessibleLocale = createAccessibleLocale(translator)

export const getAppContainer = () => ({
  // application wide config
  config,
  // routes for all modules
  routesOnePortal,
  routesSales,
  // links for all modules
  onePortalLinks,
  salesLinks,

  browserHistory,

  // global progress indicators
  showProgressOverlay,
  showProgressIndicator,
  showApiCallsIndicator,
  showProgressNotification,
  isFirstPageLoad,

  profileInfo,

  // title for navbar
  navbarTitle,

  // links for navbar
  navbarLink,

  // msal authentication
  msal,

  // i18n translation service
  translator,
  // local translations
  locales,
  // dynamically accessible locales object
  accessibleLocale,

  // api clients
  customerPortalApiClient,
  salesApiClient,

  butterCmsService,

  // services
  applicationInsights,

  // logout
  logoutService,
})

export type AppContainer = ReturnType<typeof getAppContainer>
