import { useEffect, useRef } from "react"
import { useMsalProfile } from "../hooks/useMsalProfile"
import { useLocation } from "react-router-dom"
import { GTMEvent, pushToDataLayer } from "../helpers/pushToDataLayer"

export const Qualtrics = () => {
  const location = useLocation()
  const previousLocationPathnameRef = useRef<string | undefined>()
  const profile = useMsalProfile()

  useEffect(() => {
    const currentLocation = location.pathname + location.search

    pushToDataLayer(GTMEvent.Navigate, profile, {
      previousLocation: previousLocationPathnameRef.current,
      currentLocation,
    })

    previousLocationPathnameRef.current = currentLocation
  }, [location.pathname, location.search])

  return null
}
