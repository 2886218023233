import React, { ReactNode, useEffect, useState } from "react"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { CookiesProvider } from "react-cookie"
import { ProgressOverlay } from "./components/ProgressOverlay"
import { ProgressIndicator } from "./components/ProgressIndicator"
import { RoutePolicy } from "@stg-oneportal/corets-router-helpers"
import { useMsalIsAuthenticated } from "./hooks/useMsalIsAuthenticated"
import { NotFound } from "./pages/NotFound"
import { theme } from "@op-str/op-component-library"
import { HelmetProvider } from "react-helmet-async"
import { SnackbarProvider } from "notistack"
import { useMsalStatus } from "./hooks/useMsalStatus"
import { useLogger } from "./hooks/useLogger"
import { RouteRenderErrorFallback } from "./components/RouteRenderErrorFallback"
import { AutoLogoutWhenIdle } from "./components/AutoLogoutWhenIdle"
import { useRoutes } from "./hooks/useRoutes"
import { Router } from "./router/Router"
import { useMsalProfilePermissions } from "./hooks/useMsalProfilePermissions"
import { useContainer } from "./hooks/useContainer"
import { Qualtrics } from "./components/Qualtrics"
import { StylesProvider } from "@mui/styles"

export type OneportalModuleProps = {
  children?: ReactNode
}

export const OneportalModule = (props: OneportalModuleProps) => {
  const { children } = props
  const routes = useRoutes()
  const links = useContainer().onePortalLinks
  const [mountRoutes, setMountRoutes] = useState(false)
  const isAuthenticated = useMsalIsAuthenticated()
  const msalStatus = useMsalStatus()
  const routerLogger = useLogger("Routing")
  const permissions = useMsalProfilePermissions()

  const policy = isAuthenticated ? RoutePolicy.ForAuthenticated : RoutePolicy.ForGuests

  // delay mounting of routes until msal is ready
  useEffect(() => {
    if (msalStatus === "none") {
      setMountRoutes(true)
    }
  }, [msalStatus])

  return (
    <>
      {/*inject meta information into the page head*/}
      <HelmetProvider>
        {/*configure Material UI to inject styles first, so we don't have to use !important everywhere*/}
        <StylesProvider injectFirst>
          {/*css reset provided by Material UI*/}
          <CssBaseline>
            {/*setup Material UI theme*/}
            <ThemeProvider theme={theme.mui}>
              {/*alert popups on the bottom of the screen*/}
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={theme.ui.snackbar.anchorOrigin}
                style={{
                  fontSize: "0.875rem",
                }}
              >
                <CookiesProvider>
                  {/*various global progress indicators*/}
                  <ProgressIndicator />
                  <ProgressOverlay />

                  {/*detect idle users and log them out*/}
                  <AutoLogoutWhenIdle />

                  {/*setup router*/}
                  {mountRoutes && (
                    <Router
                      context={permissions}
                      routes={routes}
                      policy={policy}
                      homePath={links.home()}
                      loginPath={links.login()}
                      logoutPath={links.logout()}
                      notAuthorizedPath={links.notAuthorized()}
                      logger={routerLogger}
                      NotFound={NotFound}
                      RenderErrorFallback={RouteRenderErrorFallback}
                    >
                      {/*enable navigation tracking*/}
                      <Qualtrics />

                      {children}
                    </Router>
                  )}
                </CookiesProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </CssBaseline>
        </StylesProvider>
      </HelmetProvider>
    </>
  )
}
