import { IPublicClientApplication } from "@azure/msal-browser"

export const createLogoutService = (msal: IPublicClientApplication, postLogoutRedirectUri: string) => {
  return {
    logout: logout(msal, postLogoutRedirectUri),
  }
}

const logout = (msal: IPublicClientApplication, postLogoutRedirectUri: string) => async () => {
  try {
    await msal.logoutRedirect({
      postLogoutRedirectUri: postLogoutRedirectUri,
    })
  } catch (err) {}
}
