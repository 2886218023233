import styled, { css } from "styled-components"

export const ContentSpaceBetween = styled.div<{ centerContent?: boolean }>`
  overflow: auto;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  min-height: 0;
  flex-direction: column;

  ${(p) =>
    p.centerContent &&
    css`
      justify-content: center;
    `}
`
