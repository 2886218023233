import { ChevronRight, HelpOutline } from "@mui/icons-material"
import { MouseEvent, useRef, useState } from "react"
import styled from "styled-components"
import { ButtonPrimary, theme } from "@op-str/op-component-library"
import { GTMEvent, pushToDataLayer } from "../../../oneportal/helpers/pushToDataLayer"
import { useLocale } from "../../../oneportal/hooks/useLocale"
import { useMsalProfile } from "../../../oneportal/hooks/useMsalProfile"
import { useOnePortalLinks } from "../../../oneportal/hooks/useOnePortalLinks"
import { ContactMenu } from "./ContactMenu"
import { HelpMenu } from "./HelpMenu"
import { LegalMenu } from "./LegalMenu"
import { useSalesLinks } from "../../hooks/useSalesLinks"
import { Link } from "react-router-dom"
import { IndentedMenuItem } from "./IndentedMenuItem"

export type HelpButtonProps = {
  linkToHelpCenter?: boolean
  showWhatsNewMenus?: boolean
  linkToSalesHelpCenter?: boolean
  contactSupport?: boolean
  legal?: boolean
  hideSectionSeparator?: boolean
}

export const HelpButton = (props: HelpButtonProps) => {
  const {
    linkToHelpCenter = true,
    linkToSalesHelpCenter = true,
    contactSupport = true,
    showWhatsNewMenus = true,
    legal = true,
    hideSectionSeparator = false,
  } = props

  const firstSectionHaveAnElement = linkToHelpCenter || showWhatsNewMenus
  const secondSectionHaveAnElement = linkToSalesHelpCenter || contactSupport || showWhatsNewMenus

  const loc = useLocale()
  const profile = useMsalProfile()
  const onePortalLinks = useOnePortalLinks()
  const salesLinks = useSalesLinks()

  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<HTMLDivElement | null>(null)
  const [contactMenuAnchorEl, setContactMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [legalMenuAnchorEl, setLegalMenuAnchorEl] = useState<HTMLElement | null>(null)
  const helpButtonRef = useRef<HTMLDivElement>(null)
  const showHelpMenu = !!helpMenuAnchorEl
  const showContactMenu = !!contactMenuAnchorEl
  const showLegalMenu = !!legalMenuAnchorEl

  const pushHelpEvent = (event: GTMEvent) => {
    pushToDataLayer(event, profile)
  }

  const handleOpenHelpMenu = () => {
    setHelpMenuAnchorEl(helpButtonRef.current)
    pushHelpEvent(GTMEvent.ShowHelp)
  }

  const handleCloseHelpMenu = () => {
    setHelpMenuAnchorEl(null)
  }

  const handleOpenContactMenu = (e: MouseEvent<HTMLLIElement>) => {
    pushHelpEvent(GTMEvent.ShowHelpContact)
    setContactMenuAnchorEl(e.currentTarget.parentElement)
  }

  const handleCloseContactMenu = () => setContactMenuAnchorEl(null)

  const handleOpenLegalMenu = (e: MouseEvent<HTMLLIElement>) => {
    pushHelpEvent(GTMEvent.ShowHelpLegal)
    setLegalMenuAnchorEl(e.currentTarget.parentElement)
  }

  const handleCloseLegalMenu = () => setLegalMenuAnchorEl(null)

  return (
    <Root>
      <GuideIntroduction ref={helpButtonRef}>
        <ButtonPrimary onClick={handleOpenHelpMenu} rounded icon={<HelpOutline />} data-cy="helpMenuButton">
          {loc.oneportal.guideMenu.actions.needHelp.get()}
        </ButtonPrimary>
      </GuideIntroduction>
      <HelpMenu
        open={showHelpMenu}
        onClose={handleCloseHelpMenu}
        anchorEl={helpMenuAnchorEl}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {firstSectionHaveAnElement && !hideSectionSeparator && (
          <SectionSeparator>{loc.sales.guideMenu.labels.section.customer.get()}</SectionSeparator>
        )}
        {linkToHelpCenter && (
          <IndentedMenuItem data-cy="onePortal_HelpItem">
            <Link to={onePortalLinks.help()} target="_blank" onClick={() => pushHelpEvent(GTMEvent.ShowHelpCenter)}>
              {loc.oneportal.guideMenu.actions.helpCenter.get()}
            </Link>
          </IndentedMenuItem>
        )}

        {showWhatsNewMenus && (
          <IndentedMenuItem data-cy="onePortal_whatIsNewItem">
            <Link
              to={onePortalLinks.customerReleases()}
              target="_blank"
              onClick={() => pushHelpEvent(GTMEvent.ShowHelpWhatIsNewCustomer)}
            >
              {loc.oneportal.guideMenu.actions.whatIsNew.get()}
            </Link>
          </IndentedMenuItem>
        )}

        {/* Space between sections only if there is two sections */}
        {firstSectionHaveAnElement && secondSectionHaveAnElement && !hideSectionSeparator && <VerticalSpacer />}

        {secondSectionHaveAnElement && !hideSectionSeparator && (
          <SectionSeparator>{loc.sales.guideMenu.labels.section.salesAndSupport.get()}</SectionSeparator>
        )}

        {linkToSalesHelpCenter && (
          <IndentedMenuItem data-cy="sales_HelpItem">
            <Link
              to={salesLinks.salesHelp()}
              target="_blank"
              onClick={() => pushHelpEvent(GTMEvent.ShowHelpCenterSales)}
            >
              {loc.sales.guideMenu.actions.salesHelp.get()}
            </Link>
          </IndentedMenuItem>
        )}

        {showWhatsNewMenus && (
          <IndentedMenuItem data-cy="sales_whatIsNewItem">
            <Link
              to={salesLinks.salesReleases()}
              target="_blank"
              onClick={() => pushHelpEvent(GTMEvent.ShowHelpWhatIsNewSalesAndSupport)}
            >
              {loc.oneportal.guideMenu.actions.whatIsNew.get()}
            </Link>
          </IndentedMenuItem>
        )}

        {contactSupport && (
          <IndentedMenuItem onClick={handleOpenContactMenu} data-cy="sales_ContactItem">
            <OpenSubMenuItem>
              {loc.oneportal.guideMenu.actions.contact.get()} <ChevronRight />
            </OpenSubMenuItem>
          </IndentedMenuItem>
        )}
        <ContactSubMenu open={showContactMenu} onClose={handleCloseContactMenu} anchorEl={contactMenuAnchorEl} />
      </HelpMenu>
    </Root>
  )
}

const Root = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: ${theme.zIndex.superHigh};
  display: flex;
  flex-direction: column-reverse;
`

const OpenSubMenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const VerticalSpacer = styled.div`
  height: ${theme.spacing.l};
`

const SeparatorLine = styled.div`
  border-bottom: solid 1px ${theme.color.grey1};
`

const SectionSeparator = styled.div`
  color: ${theme.color.grey2};
  font-size: ${theme.fontSize.s};
  font-weight: bold;
  padding: 0 ${theme.spacing.m};
  line-height: ${theme.lineHeight.m};
`

const GuideIntroduction = styled.div`
  margin-right: ${theme.spacing.l};
  margin-bottom: ${theme.spacing.l};
`

const ContactSubMenu = styled(ContactMenu)`
  margin-left: -${theme.spacing.m};
`

const LegalSubMenu = styled(LegalMenu)`
  margin-left: -${theme.spacing.m};
`
