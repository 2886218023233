import { Logger } from "@stg-oneportal/logger"

const logger = new Logger("i18next")

export const translatorLogger = {
  type: "logger",
  log: (args) => {
    // make logs about missing keys more prominent
    if (args[0]?.startsWith("i18next::translator: missingKey")) {
      logger.warn(...args)
    } else {
      logger.log(...args)
    }
  },
  warn: (args) => logger.warn(...args),
  error: (args) => logger.error(...args),
} as any
