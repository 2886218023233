import "./wdyr" // <--- first import
import "./index.scss"

import React from "react"
import { render } from "react-dom"
import { OneportalModule } from "./modules/oneportal/OneportalModule"
import { SalesPortalModule } from "./modules/sales/SalesPortalModule"
import { MsalProvider } from "@azure/msal-react"
import { useContainer } from "./modules/oneportal/hooks/useContainer"
import { I18nextProvider } from "react-i18next"

export const Root = () => {
  const container = useContainer()

  return (
    <I18nextProvider i18n={container.translator}>
      <MsalProvider instance={container.msal}>
        {/*  <AppInsightsContext.Provider value={container.applicationInsights.context}>*/}
        <OneportalModule>
          <SalesPortalModule />
        </OneportalModule>
        {/*</AppInsightsContext.Provider>*/}
      </MsalProvider>
    </I18nextProvider>
  )
}

render(<Root />, document.getElementById("root"))
