import { SalesLinks } from "./links"
import { RouteDefinition, RoutePolicy } from "@stg-oneportal/corets-router-helpers"
import { AppConfig } from "../config"
import { MsalProfilePermissions } from "../oneportal/hooks/useMsalProfilePermissions"

const privateRoutes = <TContext>(routes: RouteDefinition<TContext>[]): RouteDefinition<TContext>[] =>
  routes.map((route) => ({ ...route, policy: RoutePolicy.ForAuthenticated }))

// hide routes inside a factory to prevent global imports
export const getAppRoutes = (config: AppConfig, links: SalesLinks): RouteDefinition<MsalProfilePermissions>[] => {
  return [
    ...privateRoutes<MsalProfilePermissions>([
      {
        exact: true,
        path: "/",
        redirect: links.orders(),
      },
      {
        exact: true,
        path: links.orders(),
        load: () => import("./pages/Orders"),
      },
      {
        exact: true,
        path: links.orderDetails(),
        load: () => import("./pages/OrderDetails"),
      },
      {
        exact: true,
        path: links.salesHelp(),
        load: () => import("./pages/SalesHelp"),
      },
      {
        exact: true,
        path: links.salesReleases(),
        load: () => import("./pages/SalesReleases"),
      },
    ]),
  ]
}
