import { CircularProgress, Divider, Menu, MenuProps } from "@mui/material"
import { MailOutline, PhoneEnabledOutlined } from "@mui/icons-material"
import { Heading2, TextBold, theme } from "@op-str/op-component-library"
import { useAsync } from "@stg-oneportal/corets-use-async"
import styled from "styled-components"
import { useButterCms } from "../../../oneportal/hooks/useButterCms"
import { useMsalProfile } from "../../../oneportal/hooks/useMsalProfile"
import { Link } from "../../../oneportal/components/Link"

export type ContactMenuProps = MenuProps

export const ContactMenu = (props: ContactMenuProps) => {
  const profile = useMsalProfile()
  const butter = useButterCms()
  const language = profile?.language ?? "en"
  const contactInfo = useAsync(() => butter.getContactInfo([language])).result

  if (!contactInfo) {
    return (
      <LoadingRoot
        {...props}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CircularProgress />
      </LoadingRoot>
    )
  }

  return (
    <Root
      {...props}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Header>
        <Heading2>{contactInfo.fields.title}</Heading2>
      </Header>
      {contactInfo.fields.contact_info.map((item, index) => (
        <div key={index}>
          <ContactItem>
            <TextBold data-cy={`nameItemTitle_${index}`}>{item.title}</TextBold>
            <span data-cy={`nameItem_${index}`}>{item.subtitle}</span>
            {item.phone_number && (
              <span data-cy={`phoneNumberItem_${index}`}>
                <PhoneEnabledOutlined />
                {item.phone_number}
              </span>
            )}
            {item.email && (
              <span data-cy={`emailItem_${index}`}>
                <MailOutline />
                <Link label={item.email} link={`mailto:${item.email}`} newTab={true} />
              </span>
            )}
          </ContactItem>
          {contactInfo.fields.contact_info.length - 1 !== index && <Divider />}
        </div>
      ))}
    </Root>
  )
}

const Root = styled(Menu)`
  .MuiMenu-paper {
    padding: ${theme.spacing.lx} 0;
    margin-top: 8px;
  }

  .MuiMenu-list {
    padding: 0;
  }
`

// Identical to Root but necessary to refresh the position of the menu upon finishing loading up
const LoadingRoot = styled(Menu)`
  .MuiMenu-paper {
    padding: ${theme.spacing.lx};
    margin-top: 8px;
  }

  .MuiMenu-list {
    padding: 0;
  }
`

const Header = styled.div`
  margin-bottom: ${theme.spacing.lx};
  padding: 0 ${theme.spacing.lx};
`

const ContactItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.l} 0;
  padding: 0 ${theme.spacing.lx};
  gap: ${theme.spacing.m};
  font-size: ${theme.fontSize.s};
  line-height: ${theme.lineHeight.s};

  &:last-of-type {
    margin-bottom: 0;
  }

  span {
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: ${theme.color.primary};
    font-family: ${theme.font.bold};
  }

  svg {
    margin-right: ${theme.spacing.m};
  }
`
