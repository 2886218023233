import { Helmet } from "react-helmet-async"
import { useConfig } from "../hooks/useConfig"

export type PageTitleProps = {
  title?: string
}

export const PageTitle = (props: PageTitleProps) => {
  const config = useConfig()
  const { title } = props

  return (
    <Helmet>
      <title>{config.page.titleTemplate(title)}</title>
    </Helmet>
  )
}
