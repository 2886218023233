import { useIdle } from "@stg-oneportal/corets-use-idle"
import { useConfig } from "../hooks/useConfig"
import { useEffect } from "react"
import { useMsalIsAuthenticated } from "../hooks/useMsalIsAuthenticated"
import { useLogger } from "../hooks/useLogger"
import { useAlert } from "../hooks/useAlert"
import { useCloseAlert } from "../hooks/useCloseAlert"
import { useLocale } from "../hooks/useLocale"
import { useLogout } from "../hooks/useLogout"

export const AutoLogoutWhenIdle = () => {
  const logout = useLogout()
  const isAuthenticated = useMsalIsAuthenticated()
  const config = useConfig()
  const alert = useAlert()
  const closeAlert = useCloseAlert()
  const logger = useLogger("AutoLogout")
  const loc = useLocale()
  const isIdle = useIdle({
    interval: config.autoLogout.interval,
    threshold: config.autoLogout.threshold,
  })
  const isIdleEnough = useIdle({
    interval: config.autoLogout.interval,
    threshold: config.autoLogout.notificationThreshold,
  })
  const logoutNotificationKey = "autoLogout"

  useEffect(() => {
    if (isIdle && isAuthenticated && config.autoLogout.enabled) {
      logger.info("User is idle and will be logged out now...")
      logout.logout()
    }
  }, [isIdle])

  useEffect(() => {
    if (isIdleEnough && isAuthenticated && config.autoLogout.enabled) {
      logger.info("User is idle and will be logged out soon...")

      alert(loc.oneportal.autoLogout.messages.logoutSoon.get(), {
        variant: "warning",
        key: logoutNotificationKey,
        persist: true,
      })
    }

    if (!isIdleEnough) {
      closeAlert(logoutNotificationKey)
    }
  }, [isIdleEnough])

  return null
}
