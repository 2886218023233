import { InfoBar, InfoBarVariant } from "@op-str/op-component-library"
import { DisruptionCriticality, PageSlug } from "@stg-oneportal/axs-frontend-helpers"
import { useAsync } from "@stg-oneportal/corets-use-async"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import { useSalesLinks } from "../../sales/hooks/useSalesLinks"
import { useButterCms } from "../hooks/useButterCms"
import { useMsalProfile } from "../hooks/useMsalProfile"
import { useOnePortalLinks } from "../hooks/useOnePortalLinks"

export const DisruptionBanner = () => {
  const butterCms = useButterCms()
  const profile = useMsalProfile()
  const location = useLocation()
  const links = useOnePortalLinks()
  const salesLinks = useSalesLinks()
  const language = profile?.language ?? "en"
  const disruptionBannerStatusKey = "disruptionBannerStatusKey"
  const disruptionBannerMessage = "disruptionBannerMessage"

  const criticality = (localStorage.getItem(disruptionBannerStatusKey) ??
    DisruptionCriticality.Inactive) as DisruptionCriticality
  const message = localStorage.getItem(disruptionBannerMessage)
  const allowedToShowLinks = [links.login(), links.home(), links.help(), salesLinks.orders(), salesLinks.salesHelp()]

  const [show, setShow] = useState(criticality !== DisruptionCriticality.Inactive)

  const hide = () => {
    localStorage.setItem(disruptionBannerStatusKey, DisruptionCriticality.Inactive)
    setShow(false)
  }

  useAsync(async () => {
    if (!allowedToShowLinks.includes(location.pathname)) {
      return
    }

    const result = await butterCms.getDisruptionPage(PageSlug.SalesAxsDisruption, [language])
    if (result != null) {
      localStorage.setItem(disruptionBannerStatusKey, result.fields.criticality)
      localStorage.setItem(disruptionBannerMessage, result.fields.message)
      setShow(result.fields.criticality !== DisruptionCriticality.Inactive)
    }
  })

  if (!show) return null

  return (
    <InfoBar variant={criticality as string as InfoBarVariant} onClose={hide}>
      {message}
    </InfoBar>
  )
}
