import { MsalProfile } from "../hooks/useMsalProfile"

export enum GTMEvent {
  Navigate = "NAVIGATE",
  DownloadFile = "DOWNLOAD_FILE",
  ShowHelp = "SHOW_HELP",
  ShowHelpCenter = "SHOW_HELP_CENTER",
  ShowHelpCenterThroughSidebar = "SHOW_HELP_CENTER_THROUGH_SIDEBAR",
  ShowHelpCenterSales = "SHOW_HELP_CENTER_SALES",
  ShowHelpCenterSalesThroughSidebar = "SHOW_HELP_CENTER_SALES_THROUGH_SIDEBAR",
  ShowHelpContact = "SHOW_HELP_CONTACT",
  ShowHelpLegal = "SHOW_HELP_LEGAL",
  ShowHelpWhatIsNewCustomer = "SHOW_HELP_WHAT_IS_NEW_CUSTOMER",
  ShowHelpWhatIsNewSalesAndSupport = "SHOW_HELP_WHAT_IS_NEW_SALES_AND_SUPPORT",
  ShowWhatIsNewDialogCustomer = "SHOW_WHAT_IS_NEW_DIALOG_CUSTOMER",
  ShowWhatIsNewDialogSalesAndSupport = "SHOW_WHAT_IS_NEW_DIALOG_SALES_AND_SUPPORT",
  ExpandHelpQuestion = "EXPAND_HELP_QUESTION",
  ManualCloseCustomer = "CUSTOMER_WHATS_NEW_CLOSE",
  ManualCloseSalesAndSupport = "SALESANDSUPPORT_WHATS_NEW_CLOSE",
}

export const pushToDataLayer = (event: GTMEvent, profile: MsalProfile | undefined, data: Record<any, any> = {}) => {
  try {
    window["dataLayer"] = window["dataLayer"] || []
    window["dataLayer"].push({
      event,
      time: new Date().toISOString(),
      portal: "SalesAXS",
      currentLocation: window.location.pathname,
      user: profile
        ? {
            user_id: profile.id,
            b2cUserId: profile.id,
            email: profile.email,
            profileLanguage: profile.language,
            country: profile.countryCode,
            firstName: profile.firstName,
            lastName: profile.lastName,
          }
        : {},
      ...data,
    })
  } catch (err) {
    console.error("Could not push to dataLayer", err)
  }
}
