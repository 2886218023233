import { ArrowBack, HelpOutline } from "@mui/icons-material"
import { ButtonArea, theme } from "@op-str/op-component-library"
import clsx from "clsx"
import { forwardRef, ReactNode } from "react"
import styled from "styled-components"
import { useSalesLinks } from "../../../sales/hooks/useSalesLinks"
import { GTMEvent, pushToDataLayer } from "../../helpers/pushToDataLayer"
import { useLocale } from "../../hooks/useLocale"
import { useMsalProfile } from "../../hooks/useMsalProfile"
import { useOnePortalLinks } from "../../hooks/useOnePortalLinks"
import { Link } from "../Link"

export type SidebarProps = {
  children?: ReactNode
  onToggle: () => void
  collapsed: boolean
}
export const Sidebar = forwardRef((props: SidebarProps, ref) => {
  const { children, onToggle, collapsed } = props
  const onePortalLinks = useOnePortalLinks()
  const salesLinks = useSalesLinks()
  const loc = useLocale()
  const profile = useMsalProfile()

  return (
    <Root ref={ref as any} className={clsx(collapsed && "collapsed")}>
      <SidebarRoot>
        {children}
        <BottomContent>
          {!collapsed && (
            <>
              <HelpCenterLink>
                <HelpOutline />
                <Link
                  label={loc.sales.sidebar.labels.customerHelpCenter.get()}
                  link={onePortalLinks.help()}
                  onClick={() => pushToDataLayer(GTMEvent.ShowHelpCenterThroughSidebar, profile)}
                  newTab={true}
                />
              </HelpCenterLink>
              <HelpCenterLink>
                <HelpOutline />
                <Link
                  label={loc.sales.sidebar.labels.salesAndSupportHelpCenter.get()}
                  link={salesLinks.salesHelp()}
                  onClick={() => pushToDataLayer(GTMEvent.ShowHelpCenterSalesThroughSidebar, profile)}
                  newTab={true}
                />
              </HelpCenterLink>
            </>
          )}
          <ToggleButton onClick={onToggle}>
            <BackIcon color="primary" />
          </ToggleButton>
        </BottomContent>
      </SidebarRoot>
    </Root>
  )
})

const Root = styled.div`
  display: flex;
  width: ${theme.ui.sidebar.width};
  z-index: ${theme.zIndex.high};
  transition: width 0.2s linear;

  &.collapsed {
    width: ${theme.ui.sidebar.widthCollapsed};
  }
`

const HelpCenterLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 18px ${theme.spacing.m};
  line-height: 24px;
  width: ${theme.ui.sidebar.width};

  a {
    text-decoration: none;
  }

  svg {
    width: 24px;
    color: ${theme.color.primary};
  }
`

const SidebarRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: ${theme.mui.shadows[3]};
  background: ${theme.color.white};

  height: 100%;
  width: ${theme.ui.sidebar.width};
  transition: width 0.2s linear;

  .collapsed & {
    width: ${theme.ui.sidebar.widthCollapsed};
    transition: width 0.2s linear;
  }
`

// TODO: 6189 - Extract to component library
const ToggleButton = styled(ButtonArea)`
  display: flex;
  width: 100%;
  border-top: 1px solid ${theme.color.white1};
  height: 49px;
  flex-grow: 0;
  overflow-x: hidden;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
  padding-right: ${parseInt(theme.spacing.l) + parseInt(theme.spacing.m)}px;

  &:hover {
    background: ${theme.color.blue9};
  }

  .collapsed & {
    padding-right: 21px;
  }

  &.MuiButtonBase-root {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 21px;
  }
`

const BackIcon = styled(ArrowBack)`
  transform: rotate(0deg);
  transition: transform 0.2s linear;

  .collapsed & {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
  }
`

const BottomContent = styled.div`
  margin-top: auto;
`
