import { DateFormatPreference } from "@op-str/op-component-library"

export type AppConfig = ReturnType<typeof getAppConfig>

// hide config inside a factory to prevent global imports
export const getAppConfig = () => {
  return {
    msal: {
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID!,
      environment: process.env.REACT_APP_MSAL_ENVIRONMENT!,
      authority: process.env.REACT_APP_MSAL_AUTHORITY!,
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI!,
      postLogoutRedirectUri: process.env.REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URI!,
      knownAuthorities: process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES!,
      scopes: process.env.REACT_APP_MSAL_SCOPES!.split(","),
    },

    api: {
      customerPortalEndpoint: process.env.REACT_APP_CUSTOMER_PORTAL_API_ENDPOINT!,
      salesPortalEndpoint: process.env.REACT_APP_SALES_API_ENDPOINT!,
    },

    butterCms: {
      token: process.env.REACT_APP_BUTTER_CMS_TOKEN!,
      defaultLocale: "en",
      fetchPreview: process.env.REACT_APP_BUTTER_CMS_FETCH_PREVIEW === "true",
    },

    logger: {
      enabled: process.env.REACT_APP_ENABLE_LOGS === "true",
    },

    login: {
      // delay manual login, needed for animations
      timeout: 500,
    },

    logout: {
      // delay manual logout, needed for animations
      timeout: 500,
    },

    dateFormat: {
      defaultDateFormat: DateFormatPreference.MonthDayYear,
    },

    autoLogout: {
      enabled: process.env.REACT_APP_ENABLE_AUTO_LOGOUT === "true",
      // check once every 10 seconds
      interval: 10 * 1000,
      // user is considered idle after 20 minutes
      threshold: 20 * 60 * 1000,
      // warn user about the possible logout after 18 minutes
      notificationThreshold: 18 * 60 * 1000,
    },

    page: {
      titleTemplate: (page?: string) => (page ? `${page} | Straumann` : "Straumann"),
    },

    translator: {
      fallbackLanguage: "en",
      debug: process.env.REACT_APP_ENABLE_LOGS === "true",
    },

    eShop: {
      eShopHelpEnable: process.env.REACT_APP_ESHOP_HELP_ENABLE === "true",
    },

    applicationInsights: {
      key: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
      correlationHeaderDomains: process.env.REACT_APP_APPLICATION_INSIGHTS_CORRELATION_HEADER_DOMAINS?.split(","),
    },
  }
}
