import { Component, ReactNode } from "react"

export type RouterErrorBoundaryProps = {
  onError: (err: any) => void
  error: any
  fallback: ReactNode
  children?: ReactNode
}

export class RouterErrorBoundary extends Component<RouterErrorBoundaryProps> {
  componentDidCatch(error: any) {
    this.props.onError(error)
  }

  render() {
    if (this.props.error) {
      return this.props.fallback
    }

    return this.props.children ?? null
  }
}
