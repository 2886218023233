import { AssignmentOutlined } from "@mui/icons-material"
import React from "react"
import { SidebarSection } from "../../oneportal/components/Sidebar/SidebarSection"
import { useContainer } from "../../oneportal/hooks/useContainer"
import { useLocale } from "../../oneportal/hooks/useLocale"

export type OneportalSidebarProps = {
  collapsed?: boolean
}

export const OneportalSidebar = (props: OneportalSidebarProps) => {
  const salesLinks = useContainer().salesLinks

  const loc = useLocale()
  const sidebarLoc = loc.sales.sidebar

  return (
    <SidebarSection
      title={sidebarLoc.labels.title.get()}
      collapsedTitle={sidebarLoc.labels.collapsedTitle.get()}
      collapsed={props.collapsed}
      items={[
        {
          text: sidebarLoc.actions.ordersLink.get(),
          link: salesLinks.orders(),
          icon: <AssignmentOutlined />,
        },
      ]}
    />
  )
}
