import styled from "styled-components"
import { theme } from "@op-str/op-component-library"

export const ContentMediumWidth = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  max-width: ${theme.ui.content.widthMedium};
  height: fit-content;
`
