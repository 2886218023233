import { TextLink, TextSmall, theme } from "@op-str/op-component-library"
import { useLocale } from "../hooks/useLocale"
import styled from "styled-components"
import { useMediaQuery } from "@mui/material"
import { forwardRef } from "react"

export type FooterProps = {
  isOnPicture?: boolean
}

export const Footer = forwardRef((props: FooterProps, ref) => {
  const loc = useLocale()
  const isMobileScreen = useMediaQuery(theme.mui.breakpoints.down("md"))
  const isOnPicture = props.isOnPicture

  const ccpaLink = "https://www.straumann.com/group/us/en/landing/ccpa.html"
  const imprintLink = "https://www.straumann.com/us/en/dental-professionals/imprint.html"
  const privacyPolicyLink = "https://www.straumann.com/us/en/dental-professionals/privacy-notice.html"
  const legalNoticeLink = "https://www.straumann.com/us/en/dental-professionals/legal-notice.html"

  return (
    <FooterContainer $isMobileScreen={isMobileScreen} $isOnPicture={isOnPicture}>
      <TextSmall>{loc.oneportal.guideMenu.labels.copyright.get()}</TextSmall>
      <TextSmallGreyLink onClick={() => window.open(ccpaLink, "_blank")}>
        {loc.oneportal.guideMenu.actions.CCPA.get()}
      </TextSmallGreyLink>
      <TextSmallGreyLink onClick={() => window.open(imprintLink, "_blank")}>
        {loc.oneportal.guideMenu.actions.imprint.get()}
      </TextSmallGreyLink>
      <TextSmallGreyLink onClick={() => window.open(privacyPolicyLink, "_blank")}>
        {loc.oneportal.guideMenu.actions.privacyPolicy.get()}
      </TextSmallGreyLink>
      <TextSmallGreyLink onClick={() => window.open(legalNoticeLink, "_blank")}>
        {loc.oneportal.guideMenu.actions.legalNotice.get()}
      </TextSmallGreyLink>
    </FooterContainer>
  )
})

const FooterContainer = styled.div<{ $isMobileScreen: boolean; $isOnPicture?: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.$isMobileScreen ? "column" : "row")};
  padding: ${theme.spacing.l} ${theme.spacing.lx};
  gap: ${theme.spacing.l};
  ${(p) => p.$isOnPicture && "background-color: rgba(255,255,255,70%); position: fixed; bottom: 0; width: 100%;"};
`

const TextSmallGreyLink = styled(TextLink)`
  color: ${theme.color.grey2};
  font-size: ${theme.fontSize.s};
  line-height: ${theme.lineHeight.s};
  font-family: ${theme.font.regular};
`
