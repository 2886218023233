import styled from "styled-components"
import { ButtonArea, theme } from "@op-str/op-component-library"
import { forwardRef, ReactNode } from "react"
import { ArrowBack } from "@mui/icons-material"
import clsx from "clsx"

export type PanelProps = {
  children?: ReactNode
  collapsable?: boolean
  onToggle: () => void
  collapsed: boolean
}

export const Panel = forwardRef((props: PanelProps, ref) => {
  const { children, onToggle, collapsed, collapsable = false } = props

  return (
    <Root ref={ref as any} className={clsx(collapsed && "collapsed")}>
      <PanelRoot>
        {children}
        {collapsable && (
          <ToggleButton onClick={onToggle}>
            <BackIcon color="primary" />
          </ToggleButton>
        )}
      </PanelRoot>
    </Root>
  )
})

const Root = styled.div`
  display: flex;
  width: ${theme.ui.panel.width};
  z-index: ${theme.zIndex.low};
  transition: width 0.2s linear;

  &.collapsed {
    width: ${theme.ui.panel.widthCollapsed};
  }

  ${theme.mui.breakpoints.down("md")} {
    width: ${theme.ui.panel.widthCollapsed};
    transition: width 0.2s linear;
  }
`

const PanelRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: ${theme.mui.shadows[3]};
  background: ${theme.color.white3};

  height: 100%;
  width: ${theme.ui.panel.width};
  transition: width 0.2s linear;

  .collapsed & {
    width: ${theme.ui.panel.widthCollapsed};
    transition: width 0.2s linear;
  }

  ${theme.mui.breakpoints.down("md")} {
    transform: translateX(-${parseInt(theme.ui.panel.width) - parseInt(theme.ui.panel.widthCollapsed)}px);
    transition: transform 0.2s linear;

    .collapsed & {
      transform: translateX(0px);
      width: ${theme.ui.panel.widthCollapsed};
      transition: transform 0.2s linear, width 0.2s linear;
    }
  }
`

const ToggleButton = styled(ButtonArea)`
  display: flex;
  width: 100%;
  border-top: 1px solid ${theme.color.white1};
  height: 49px;
  flex-grow: 0;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  padding-left: ${parseInt(theme.spacing.l) + parseInt(theme.spacing.m)}px;

  &:hover {
    background: ${theme.color.blue9};
  }

  .collapsed & {
    padding-left: 21px;
  }
`

const BackIcon = styled(ArrowBack)`
  transform: rotate(180deg);
  transition: transform 0.2s linear;

  .collapsed & {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }
`
