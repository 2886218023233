import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useContainer } from "../hooks/useContainer"

export const NotFound = () => {
  const history = useHistory()
  const links = useContainer().onePortalLinks

  // redirect to home page
  useEffect(() => {
    history.push(links.home())
  }, [])

  return null
}
