import styled from "styled-components"
import { ReactNode } from "react"
import { ButtonArea, TextBold, TextRegular, theme } from "@op-str/op-component-library"
import { NavLink } from "react-router-dom"

type SidebarItem = {
  text: string
  icon: ReactNode
  link: string
  exact?: boolean
  show?: boolean
  new?: boolean
}

export type SidebarSectionProps = {
  title: string
  collapsedTitle?: string
  collapsed?: boolean
  items?: (SidebarItem | false | null | undefined)[]
}

export const SidebarSection = (props: SidebarSectionProps) => {
  const { title, collapsedTitle, collapsed } = props
  const isSidebarItem = (item: SidebarItem | false | null | undefined): item is SidebarItem =>
    !!item && item.show !== false

  const items = props.items?.filter(isSidebarItem) ?? []

  return (
    <Root>
      <TitleWrapper>{collapsed ? <Title>{collapsedTitle}</Title> : <Title noWrap>{title}</Title>}</TitleWrapper>
      <List>
        {items.map((item, index) => (
          <ListItem key={index}>
            {item?.link && (
              <ListItemLinkContainer>
                <ListItemLink to={item.link} exact={item?.exact}>
                  {item?.icon}
                  <TextRegular noWrap>{item.text}</TextRegular>
                </ListItemLink>
              </ListItemLinkContainer>
            )}
          </ListItem>
        ))}
      </List>
    </Root>
  )
}

const Root = styled.div``

const Title = styled(TextBold)`
  align-items: center;
  text-transform: uppercase;
  overflow: hidden;
  transition: opacity 0.2s linear, height 0.2s linear;
  white-space: nowrap;
  padding-left: ${theme.spacing.l};
  padding-right: ${theme.spacing.s};
`

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const ListItem = styled.li`
  white-space: nowrap;

  svg {
    margin-right: ${theme.spacing.l};
    width: 25px;
    flex-shrink: 0;
  }
`

const ListItemLinkContainer = styled(ButtonArea)`
  width: 100%;
`

const ListItemLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: inherit;
  border-bottom: 1px solid ${theme.color.white1};
  height: 49px;
  font-size: ${theme.fontSize.m};
  flex-grow: 1;
  padding-left: ${parseInt(theme.spacing.l) + parseInt(theme.spacing.m)}px;
  transition: border-left 0.2s linear, padding-left 0.2s linear;

  &:hover {
    background: ${theme.color.primary50};
  }

  &.active {
    background: ${theme.color.primary50};
    border-left: ${theme.spacing.m} solid ${theme.color.primary};
    padding-left: ${theme.spacing.l};
  }

  .collapsed & {
    border-left-width: 0;
    padding-left: 24px;
    transition: border-left-width 0.2s linear, padding-left 0.2s linear;

    span,
    p {
      opacity: 0;
      transition: opacity 0.2s linear;
    }
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.color.white1};
  height: 49px;
  padding-right: ${theme.spacing.l};
`
