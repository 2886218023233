import { User } from "./types/User"
import { ApiClient } from "../ApiClient"

export const createCustomerPortalApiClient = (api: ApiClient) => {
  return {
    getMyProfile: getMyProfile(api),
    getMyAvatar: getMyAvatar(api),
  }
}

const getMyProfile = (api: ApiClient) => async (): Promise<User | undefined> => {
  try {
    const res = await api.get<User>("/users/me")

    return res.data
  } catch (err) {}
}

const getMyAvatar = (api: ApiClient) => async (): Promise<string | undefined> => {
  try {
    const res = await api.get<string>("/users/me/avatar")

    return res.data
  } catch (err) {}
}
