import { MenuProps } from "@mui/material"
import styled from "styled-components"
import { useLocale } from "../../../oneportal/hooks/useLocale"
import { HelpMenu } from "./HelpMenu"
import { IndentedMenuItem } from "./IndentedMenuItem"

export type LegalMenuProps = MenuProps

export const LegalMenu = (props: LegalMenuProps) => {
  const loc = useLocale()
  const myLoc = loc.oneportal.guideMenu

  return (
    <HelpSubMenu
      {...props}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <IndentedMenuItem>
        <a href="https://www.straumann.com/us/en/dental-professionals/imprint.html" target="_blank">
          {myLoc.actions.imprint.get()}
        </a>
      </IndentedMenuItem>
      <IndentedMenuItem disabled>
        <span>{myLoc.labels.copyright.get()}</span>
      </IndentedMenuItem>
    </HelpSubMenu>
  )
}

const HelpSubMenu = styled(HelpMenu)`
  .MuiMenu-paper {
    margin-top: 8px;
  }
`
