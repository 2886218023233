import { ChunkedRequest } from "../types/ChunkedRequest"
import { ChunkedResponse } from "../types/ChunkedResponse"

export const createChunkedResponse = <TData>(
  req: ChunkedRequest,
  data: TData[] = [],
  totalNumberOfResults: number
): ChunkedResponse<TData> => {
  const hasMore = totalNumberOfResults > req.offset + req.limit

  return {
    offset: req.offset,
    limit: req.limit,
    data,
    hasMore,
  }
}
