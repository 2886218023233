import { useShowProgressIndicator } from "../hooks/useShowProgressIndicator"
import styled from "styled-components"
import { theme } from "@op-str/op-component-library"
import { AnimatePresence, motion } from "framer-motion"
import { useCallback, useEffect, useState } from "react"
import { throttle } from "lodash-es"
import { useShowApiCallsIndicator } from "../hooks/useShowApiCallsIndicator"

export const ProgressIndicator = () => {
  const showProgressIndicator = useShowProgressIndicator().get()
  const showApiCallsIndicator = useShowApiCallsIndicator().get()
  const showIndicator = showProgressIndicator || showApiCallsIndicator

  const [show, setShow] = useState(showIndicator)
  const setShowThrottled = useCallback(throttle(setShow, 800), [])

  useEffect(() => {
    setShowThrottled(showIndicator)
  }, [showIndicator])

  return (
    <AnimatePresence exitBeforeEnter>
      {show && (
        <Root initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Indicator
            initial={{ x: "-600px" }}
            animate={{ x: "100vw" }}
            transition={{ duration: 1, repeat: Infinity, type: "linear" }}
          />
        </Root>
      )}
    </AnimatePresence>
  )
}

const Root = styled(motion.div)`
  width: 100vw;
  height: ${theme.ui.progressIndicator.height};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${theme.zIndex.high};
`

const Indicator = styled(motion.div)`
  height: 100%;
  width: 600px;
  background: ${theme.ui.progressIndicator.backgroundColor};
`
