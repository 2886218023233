import { ApiClient } from "../../../oneportal/services/api/ApiClient"
import { OrderDocumentsInfo } from "./types/OrderDocumentsInfo"
import { OrderInvoicesInfo } from "./types/OrderInvoicesInfo"
import { OrderOverviewInfo } from "./types/OrderOverviewInfo"
import { Document } from "./types/Document"
import { createChunkedRequest } from "../../../oneportal/services/api/helpers/createChunkedRequest"
import { createChunkedResponse } from "../../../oneportal/services/api/helpers/createChunkedResponse"
import { ChunkedRequest } from "../../../oneportal/services/api/types/ChunkedRequest"
import { ChunkedResponse } from "../../../oneportal/services/api/types/ChunkedResponse"
import { SortedRequest } from "../../../oneportal/services/api/types/SortedRequest"
import { OrderDetailsInfo } from "./types/OrderDetailsInfo"
import { OrderHistoryInfo } from "./types/OrderHistoryInfo"
import { Order } from "./types/Order"
import { OrderId } from "./types/tags/OrderId"
import { OrderSummary } from "./types/OrderSummary"
import { User } from "./types/User"
import { createBrowserHistory } from "history"
import { getOnePortalAppLinks } from "../../../oneportal/links"
import { OnboardingStatus } from "./types/OnboardingStatus"

export const createSalesApiClient = (api: ApiClient) => {
  return {
    getSalesProfile: getSalesProfile(api),
    getOnboarding: getOnboarding(api),
    updateOnboardingStatus: updateOnboardingStatus(api),
    getOrders: getOrders(api),
    getOrder: getOrder(api),
    getOrderOverviewInfo: getOrderOverviewInfo(api),
    getOrderDetailsInfo: getOrderDetailsInfo(api),
    getOrderHistoryInfo: getOrderHistoryInfo(api),
    getOrderDocumentsInfo: getOrderDocumentsInfo(api),
    getOrderInvoicesInfo: getOrderInvoicesInfo(api),
    getDocumentInBlob: getDocumentInBlob(api),
    downloadAllInvoicesInZip: downloadAllInvoicesInZip(api),
    downloadMultipleInvoicesInZip: downloadMultipleInvoicesInZip(api),
    downloadMultipleDocumentsInZip: downloadMultipleDocumentsInZip(api),
  }
}

const getSalesProfile = (api: ApiClient) => async (): Promise<User | undefined> => {
  const history = createBrowserHistory({ forceRefresh: true })
  try {
    const res = await api.get<User>("/users/me")

    return res.data
  } catch (err) {
    history.push(getOnePortalAppLinks().notAuthorized())
  }
}

const getOnboarding = (api: ApiClient) => async (): Promise<OnboardingStatus | undefined> => {
  try {
    const res = await api.get<OnboardingStatus>("/users/me/onboarding")
    return res.data
  } catch (err) {}
}

const updateOnboardingStatus =
  (api: ApiClient) =>
  async (data: OnboardingStatus): Promise<OnboardingStatus | undefined> => {
    try {
      const res = await api.patch<OnboardingStatus>("/users/me/onboarding", data)
      return res.data
    } catch (err) {}
  }

export type GetOrdersRequest = Partial<ChunkedRequest> &
  SortedRequest & {
    searchText: string
  }

export type GetOrderSummariesResponse = {
  totalNumberOfResults: number
  results: OrderSummary[]
}

export type GetOrderSummariesChunkedResponse = {
  totalNumberOfResults: number
  orderSummaries: ChunkedResponse<OrderSummary>
}

const getOrders =
  (api: ApiClient) =>
  async (args?: GetOrdersRequest): Promise<GetOrderSummariesChunkedResponse | undefined> => {
    try {
      const req = createChunkedRequest(args)
      const res = await api.post<GetOrderSummariesResponse>("/orders/search", req)
      return {
        ...res.data,
        totalNumberOfResults: res.data.totalNumberOfResults,
        orderSummaries: createChunkedResponse(req, res.data.results, res.data.totalNumberOfResults),
      }
    } catch (err) {}
  }

const getOrder =
  (api: ApiClient) =>
  async (orderId: OrderId): Promise<Order | undefined> => {
    try {
      const res = await api.get<Order>(`orders/${orderId}`)
      return res.data
    } catch (err) {}
  }

const getOrderOverviewInfo =
  (api: ApiClient) =>
  async (orderId: OrderId): Promise<OrderOverviewInfo | undefined> => {
    try {
      const res = await api.get<OrderOverviewInfo>(`orders/${orderId}/overview-info`)
      return res.data
    } catch (err) {}
  }

const getOrderDetailsInfo =
  (api: ApiClient) =>
  async (orderId: OrderId): Promise<OrderDetailsInfo | undefined> => {
    try {
      const res = await api.get<OrderDetailsInfo>(`orders/${orderId}/details-info`)
      return res.data
    } catch (err) {}
  }

const getOrderHistoryInfo =
  (api: ApiClient) =>
  async (orderId: OrderId): Promise<OrderHistoryInfo | undefined> => {
    try {
      const res = await api.get<OrderHistoryInfo>(`orders/${orderId}/history-info`)
      return res.data
    } catch (err) {}
  }

const getOrderInvoicesInfo =
  (api: ApiClient) =>
  async (orderId: OrderId): Promise<OrderInvoicesInfo | undefined> => {
    try {
      const res = await api.get<OrderInvoicesInfo>(`orders/${orderId}/invoices-info`)
      return res.data
    } catch (err) {}
  }

const getDocumentInBlob =
  (api: ApiClient) =>
  async (document: Document): Promise<Blob | undefined> => {
    try {
      const res = await api.get<Blob>(`${document.downloadUrl}`, { responseType: "blob" })

      return new Blob([res.data], { type: document.mimeType })
    } catch (err) {}
  }

export type DownloadMultipleInvoicesRequest = {
  orderId: OrderId
  invoiceIds: string[]
}

const downloadMultipleInvoicesInZip =
  (api: ApiClient) =>
  async (req: DownloadMultipleInvoicesRequest): Promise<ArrayBuffer | undefined> => {
    try {
      const response = await api.post<ArrayBuffer>("orders/invoices/selected/zip-download", req, {
        responseType: "arraybuffer",
      })

      return response.data
    } catch (err) {}
  }

const downloadAllInvoicesInZip =
  (api: ApiClient) =>
  async (orderId: OrderId): Promise<ArrayBuffer | undefined> => {
    try {
      const response = await api.get<ArrayBuffer>(`/orders/${orderId}/invoices/zip-download`, {
        responseType: "arraybuffer",
      })

      return response.data
    } catch (err) {}
  }

const getOrderDocumentsInfo =
  (api: ApiClient) =>
  async (orderId: OrderId): Promise<OrderDocumentsInfo | undefined> => {
    try {
      const res = await api.get<OrderDocumentsInfo>(`orders/${orderId}/documents-info`)
      return res.data
    } catch (err) {}
  }

export type DownloadMultipleDocumentsRequest = {
  orderId: OrderId
  documentIds: string[]
}

const downloadMultipleDocumentsInZip =
  (api: ApiClient) =>
  async (req: DownloadMultipleDocumentsRequest): Promise<ArrayBuffer | undefined> => {
    try {
      const response = await api.post<ArrayBuffer>("orders/documents/selected/zip-download", req, {
        responseType: "arraybuffer",
      })
      return response.data
    } catch (err) {}
  }
