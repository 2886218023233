import { useShowProgressOverlay } from "../hooks/useShowProgressOverlay"
import styled from "styled-components"
import { theme } from "@op-str/op-component-library"
import { AnimatePresence, motion } from "framer-motion"
import { DarkTextLogo } from "../../../assets/logos"

export const ProgressOverlay = () => {
  const showOverlay = useShowProgressOverlay().get()

  return (
    <AnimatePresence>
      {showOverlay && (
        <Root initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
          <DarkTextLogo />
        </Root>
      )}
    </AnimatePresence>
  )
}

const Root = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${theme.color.white3};
  z-index: ${theme.zIndex.veryHigh};

  svg {
    width: 200px;
  }
`
