import { OnePortalLinks } from "./links"
import { RouteDefinition, RouteGuard, RoutePolicy } from "@stg-oneportal/corets-router-helpers"
import { AppConfig } from "../config"
import { MsalProfilePermissions } from "./hooks/useMsalProfilePermissions"

const publicRoutes = <TContext>(routes: RouteDefinition<TContext>[]): RouteDefinition<TContext>[] =>
  routes.map((route) => ({ ...route, policy: RoutePolicy.ForGuests }))

const privateRoutes = <TContext>(routes: RouteDefinition<TContext>[]): RouteDefinition<TContext>[] =>
  routes.map((route) => ({ ...route, policy: RoutePolicy.ForAuthenticated }))

// hide routes inside a factory to prevent global imports
export const getAppRoutes = (config: AppConfig, links: OnePortalLinks): RouteDefinition<MsalProfilePermissions>[] => {
  return [
    ...publicRoutes<MsalProfilePermissions>([
      {
        path: "/",
        load: () => import("../sales/pages/Login"),
        exact: true,
      },
      {
        path: links.login(),
        load: () => import("../sales/pages/Login"),
      },
    ]),

    ...privateRoutes<MsalProfilePermissions>([
      {
        path: links.logout(),
        load: () => import("./pages/Logout"),
        guard: RouteGuard.RedirectHomeOrLogin,
      },
      {
        path: links.notAuthorized(),
        load: () => import("./pages/NotAuthorized"),
      },
      {
        path: links.help(),
        load: () => import("./pages/Help"),
      },
      {
        path: links.discovery(),
        load: () => import("./pages/Discovery"),
      },
      {
        path: links.customerReleases(),
        load: () => import("./pages/CustomerReleases"),
      },
    ]),
  ]
}
