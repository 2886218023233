import { ArrowBack, Close } from "@mui/icons-material"
import clsx from "clsx"
import { ReactNode } from "react"
import styled, { css, keyframes } from "styled-components"
import { Logo, Text } from "../../../../assets/logos"
import { theme } from "@op-str/op-component-library"
import { NavbarLinks } from "./NavbarLinks"
import { BackLinkType } from "./types/BackLinkType"
import { useContainer } from "../../hooks/useContainer"
import { ButtonLinkArea } from "./ButtonLinkArea"

export type NavbarProps = {
  backLink?: string
  backLinkType?: BackLinkType
  layout: "action" | "logo"
  animate: boolean
  customRightContent?: ReactNode
  customProfile?: ReactNode
  collapsed?: boolean
}

let previousLayout: string | undefined = undefined

export const Navbar = (props: NavbarProps) => {
  const { backLink, backLinkType = BackLinkType.Back, layout, animate, collapsed = false, customProfile } = props
  const links = useContainer().onePortalLinks

  previousLayout = layout

  return (
    <Root>
      <Content>
        <AnimatedContentArea className={clsx(layout, animate && "animate")}>
          <LeftContent>
            {!backLink && (
              <LogoLink to={links.home()} $collapsed={collapsed}>
                <Text className="logo-with-text" />
                <Logo className="logo" />
              </LogoLink>
            )}

            {backLink && (
              <BackLink to={backLink}>
                <BackLinkLogo type={backLinkType} />
              </BackLink>
            )}
          </LeftContent>
          <MiddleContent>
            <NavbarLinks />
          </MiddleContent>
        </AnimatedContentArea>
        <RightContent>{!!customProfile && <ProfileWrapper>{customProfile}</ProfileWrapper>}</RightContent>
      </Content>
    </Root>
  )
}

const BackLinkLogo = (props: { type: BackLinkType }) => {
  if (props.type === BackLinkType.Back) return <ArrowBack />
  if (props.type === BackLinkType.Close) return <Close />
  return null
}

const Root = styled.div`
  display: flex;
  flex-shrink: 0;
  height: ${theme.ui.navbar.height};
  background: ${theme.ui.navbar.backgroundColor};
  overflow-x: auto;
  color: ${theme.color.white3};
  z-index: ${theme.zIndex.medium};
`

const Content = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
`

const animateLogo = keyframes`
  from {
    transform: translateX(-${parseInt(theme.ui.navbar.logoWidth) - parseInt(theme.ui.navbar.actionWidth)}px);
  }

  to {
    transform: translateX(0px);
  }
`

const animateAction = keyframes`
  from {
    transform: translateX(${parseInt(theme.ui.navbar.logoWidth) - parseInt(theme.ui.navbar.actionWidth)}px);
  }

  to {
    transform: translateX(0px);
  }
`

const AnimatedContentArea = styled.div`
  display: flex;
  flex-grow: 1;

  ${theme.mui.breakpoints.up("md")} {
    &.logo.animate {
      animation: ${animateLogo} 0.2s linear;
    }

    &.action.animate {
      animation: ${animateAction} 0.2s linear;
    }
  }
`

const LeftContent = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-right: 1px solid ${theme.color.grey1};
  opacity: 1;

  svg {
    color: ${theme.color.white3};
    height: 45%;
  }
`

const animateLogoAndAction = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const BackLink = styled(ButtonLinkArea)`
  height: 100%;
  align-items: center;
  justify-content: center;
  width: ${theme.ui.navbar.actionWidth};

  .animate & {
    animation: ${animateLogoAndAction} 0.2s linear;
  }
`

const collapsedLogoStyles = () => css`
  width: ${theme.ui.navbar.logoWidthSmall};

  .logo-with-text {
    opacity: 0;
  }
`

const LogoLink = styled(ButtonLinkArea)<{ $collapsed: boolean }>`
  height: 100%;
  align-items: center;
  width: ${theme.ui.navbar.logoWidth};
  padding-left: ${theme.spacing.l};
  transition: width 0.2s linear;

  .logo-with-text {
    transition: opacity 0.2s linear;
    position: absolute;
    left: 55px;
  }
  .animate & {
    opacity: 1;
  }

  &:hover {
    background-color: ${theme.color.black3};
  }

  ${(p) =>
    p.$collapsed &&
    css`
      ${collapsedLogoStyles}
    `}

  ${theme.mui.breakpoints.down("md")} {
    ${collapsedLogoStyles}
  }
`

const MiddleContent = styled.div`
  flex-grow: 1;
`

const RightContent = styled.div`
  display: flex;
  max-width: 320px;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const ProfileWrapper = styled.div`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 248px;
  height: 100%;
  border-left: 1px solid ${theme.color.grey1};
`
